<template>
  <div>
    <a-row :gutter="24">
      <a-col :xl="24" :lg="24" :md="24" :sm="24" :xs="24">
        <a-card :bordered="false" title="项目进展" >
          <ve-histogram
            :title="titleProgress"
            :data="chartDataProgress"
            :extend="extendProgress"
            judge-width
          ></ve-histogram>
        </a-card>
      </a-col>
    </a-row>
    
    <a-row :gutter="24">
      <a-col :xl="24" :lg="24" :md="24" :sm="24" :xs="24">
        <a-card :bordered="false" style="margin-top: 20px; " title="任务汇总情况">
          <ve-histogram
            :title="titleTask"
            :data="chartDataTask"
            :extend="extendTask"
            judge-width
            >
            </ve-histogram>
        </a-card>
      </a-col>
      
    </a-row>
  </div>
</template>

<script>
import Lib from "echarts/lib/echarts";
import { projectProgress, projectTask } from "@/httpsAPI/effect";


export default {
  name: "effectManage",
  data() {
    return {
      titleProgress: {
        text: "项目数量(个)",
      },
      titleTask: {
        text: "任务数量(个)",
      },
      tooltip: {
        show: false,
        trigger: "none",
      },
      chartDataProgress: {
        columns: ["工作项", "未开始","进行中","已完成"],
        rows: [],
      },
      chartDataTask: {
        columns: ["工作项", "正常任务", "预警任务", "逾期任务"],
        rows: [],
      },
      extendProgress: {
        color: ["#f5222d", "#fa8c16", "#52c41a"],
        series: {
          type: "bar",
          label: { show: false, position: "top" },
        },
      },
      extendTask: {
        color: ["#52c41a", "#fa8c16", "#f5222d"],
        series: {
          type: "bar",
          label: { show: true, position: "top" },
        },
      },
    };
  },
  components: {
    Lib,
  },
  methods: {
    getProjectChartData() {
      const data = {};
      projectProgress(data).then((res) => {
        this.chartDataProgress.rows = [{ "工作项":"项目汇总数量", "未开始": res.data[0].notStart, "进行中": res.data[0].underway, "已完成": res.data[0].completed}]
      });
    },
    getTaskChartData() {
      const data = {};
      projectTask(data).then((res) => {
        console.log(res)
        for(var i in res.data) {
          this.chartDataTask.rows.push({ 工作项: res.data[i].yearMonth, 正常任务:res.data[i].normal, 预警任务: res.data[i].warning, 逾期任务: res.data[i].overdue })
        }
        console.log(this.chartDataTask.rows)
      });
    },
  },
  created() {
    this.getProjectChartData()
    this.getTaskChartData()
  }
};
</script>

<style scoped></style>

import request from "@/utils/request";

// 效能管理-项目进展情况
export function projectProgress(data) {
  return request({
    url: "/report/project",
    method: "post",
    data
  });
}

// 效能管理-项目任务情况
export function projectTask(data) {
  return request({
    url: "/report/task",
    method: "post",
    data
  });
}

// 人员能力-升级数对比
export function userLevel(data) {
  return request({
    url: "/report/userLevel",
    method: "post",
    data
  });
}

// 资源应用-总体情况
export function resourcesMoth(data) {
  return request({
    url: "/report/resourcesMoth",
    method: "post",
    data
  });
}

// 资源应用-资源利用前10个用户情况
export function resourcesUser(data) {
  return request({
    url: "/report/resourcesUser",
    method: "post",
    data
  });
}
// 资源应用-个人应用汇总全部数据
export function resourcesMore(data) {
  return request({
    url: "/report/resourcesAllUser",
    method: "post",
    data
  });
}